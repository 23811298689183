var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "p-0 m-0" },
    [
      _c(
        "CCol",
        { staticClass: "p-0 m-0", attrs: { md: "12" } },
        [
          _c(
            "CCard",
            { staticClass: "p-0 m-0 rounded" },
            [
              _c(
                "CCardHeader",
                { staticClass: "p-0 m-0", attrs: { color: "gradient-info" } },
                [
                  _c(
                    "CRow",
                    {
                      staticClass: "justify-content-end pb-0 mb-0",
                      attrs: { color: "info" }
                    },
                    [
                      false
                        ? _c(
                            "CCol",
                            { staticClass: "pb-0 mb-0" },
                            [
                              _c("CInput", {
                                attrs: {
                                  label: "Date",
                                  type: "date",
                                  horizontal: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "CCol",
                        {
                          staticClass: "mr-2 pt-1 pl-4 align-righ pb-0 mb-0",
                          attrs: { sm: "12", md: "6", xs: "12" }
                        },
                        [
                          false
                            ? _c("CSelect", {
                                staticClass: "pb-1 mb-0",
                                attrs: {
                                  options: _vm.relativeTimes,
                                  value: _vm.relativeTimeSelected
                                },
                                on: {
                                  "update:value": function($event) {
                                    _vm.relativeTimeSelected = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [
                                          _c("CIcon", {
                                            attrs: { name: "cil-clock" }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1384365332
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCardBody",
                {
                  staticClass: "p-0 m-0 rounded h-100 overflow-hidden dash-card"
                },
                [
                  _c("GrafanaEmbeddedDashboard", {
                    attrs: {
                      src: _vm.urlPath,
                      from: _vm.from,
                      to: _vm.to,
                      kiosk: "tv"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }