<template>
  <div>
    
    <CRow>
      <CCol lg="12" color="warning">
        <template slot="title">
          <CIcon name="cil-clock"></CIcon> History
        </template>

        <CRow class="pb-0 mb-0">
          <CCol lg="12" class="dashboard-container ">
            <AnalyticsBaseView  :device="selected" class="p-0 m-0">
            </AnalyticsBaseView >
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <CElementCover
      v-show="isLoading"
      :boundaries="[{ sides: ['top', 'left'], query: '#media-body' }]"
      :opacity="0.1"
    >
      <h1 class="d-inline">Loading...</h1>
      <CSpinner size="6xl" color="info" />
    </CElementCover>
  </div>
</template>

<style scoped>
.dashboard-container {
  min-height: 500px;
  overflow: hidden;
  min-height: 500px;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
}

.tab-container {
  border-color: var(--warning);
  border-width: 10px;
  border-radius: 5px;
}

.tab {
  border-radius: 5px;
  margin-bottom: -1px;
  border-top: none;
}

.button {
  /*background-color: var(--theme-gradient);*/
  /*background-image: var(--theme-gradient);*/
  border-width: 0px;
  border-style: solid;
  /*border-image: var(--theme-gradient);*/
}

.button-color {
  background-color: var(--info);
}

.vue-grid-item.vue-grid-placeholder {
  background: gray !important;
  border: thin dashed !important;
}
</style>


<script>
import AnalyticsBaseView from "@/views/main/analytics/AnalyticsBaseView"; 
import axios from "axios"; // The Dashboard Code will Be allowed to Make Requests

const baseLayout = [
  { i: "0", x: 0, y: 0, w: 4, h: 16, accent: "primary" },
  { i: "1", x: 4, y: 0, w: 4, h: 12, accent: "secondary", static: true },
  { i: "2", x: 8, y: 0, w: 4, h: 12, accent: "success" }
];
const layoutKey = "CoreUI-Vue-Draggable-Layouts";
const storedLayout = JSON.parse(localStorage[layoutKey] || null);

import { inventory } from "../data/appData";

const defaultRadioInfo = {
  time: "2020-11-13T09:10:29.591Z",
  rssi: -60,
  stat: 0,
  truck: 717201
};

export default {
  name: "Analytics",
  components: {
    AnalyticsBaseView 
  },
  data() {
    return {
      selected: null, // Object // TODO: this should be a Calculated value as well with a getter and setter
      layout: storedLayout || baseLayout,
      storedLayout,
      gridKey: 0,
      isLoading: false,
      deviceOptions: [],

      devices: [], // deviceList,
      deviceStatus: {
        radioInfo: defaultRadioInfo,
        recentActivity: [],
        deviceInfo: {}
      }
    };
  },
  computed: {
    selectedOption: {
      get() {
        return this.deviceOptions.find(x => x == this.selected);
      },
      set(value) {
        this.selected = value;
        this.onSelect(value);
      }
    },
    /** Gets/ Sets the Selected Item from the drop dowm list
     * Setting the value will clear controls and fetch new info from the server
     */
    selectedItemName: function() {
      let togglerText = "";
      try {
        if (this.selected) {
          // If We've Selected A Deivce
          togglerText = this.selected.name.toString();
        } else {
          togglerText = "No Device Selected";
        }
      } catch (err) {
        console.log(err);
      } finally {
        return togglerText;
      }
    }
  },
  created: function() {
    try {
      if (this.$route.query["id"]) {
      }
    } catch (err) {
      console.log(err);
    }
  },
  mounted: async function() {
    // TODO: Persist the Data in Local Storage to avoid this query again!
    if (!this.devices || this.devices.length == 0) {
      this.isLoading = true;
      try {
        {
          let dashes = await this.$api.getDashboards({
            type: "grafana",
            key: this.key
          });
          console.log(dashes.data);
          if (dashes.status === 200) {
            this.$store.commit("updateDashboards", dashes.data); // TODO: Do we want to do this here?
          }

          let { data } = await this.loadInventory();

          let deviceIndex = 0;
          let deviceId = this.$route.query["id"];
          if (data) {
            this.devices = []; //
            data.forEach((element, index) => {
              //console.log(element);
              if (deviceId === element.device) {
                deviceIndex = index;
              }
              this.devices.push(element);
              this.deviceOptions.push({
                id: element.device,
                value: element,
                label: element.name
              });
            });
            // Notify Complete
            this.onSelect(this.devices[deviceIndex]);
          }
        }
      } catch (err) {
        console.warn(err);
      } finally {
        //this.$store.commit("updateData", ["inventory", this.devices]);
        this.isLoading = false;
      }
    }
  },
  methods: {
    async loadDashboards() {
      console.log("Retrieveing Dashboards");
      //return await this.$api.getDashboards({ type: "grafana", key: this.key });

      //let {res} = await this.$api.getDashboards({ type: "grafana", key: this.key });
      //console.log(res);
      //this.$store.commit("updateDashboards", res); // TODO: Do we want to do this here?

      /*
        this.$api
          .getDashboards({ type: "grafana", key: this.key })
          .then(res => {
            if (res.status === 200) {
              console.log(res.data); 
              this.$store.commit("updateDashboards", res.data); // TODO: Do we want to do this here? 
              //this.dashboardList = this.mapData(this.$store.state.dataStore.dashboards[this.key]);
              
            }
          }).catch((err)=> {
            console.log("Failed to Load Dashboards" + err); 
          })
          */
    },
    /** Deep Copy  */
    copyReactiveObject(source, dest) {
      let keys = Object.keys(source);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        console.log(key);
        if (dest[key]) {
          dest[key] = source[key];
        }
      }
    },
    async loadInventory() {
      //return this.$api.getInventory();
      return await this.$api.getInventory();
      //alert(tempData);
      try {
        let { tempData } = await this.$api.getInventory().then();
        return tempData;
        if (!this.$store.state.dataStore.inventory) {
          console.log(data.data);

          this.$store.commit("updateData", ["inventory", data.data]);
        }
      } catch (err) {
        console.log(err);
      } finally {
        return this.$store.state.dataStore.inventory;
      }
    },
    async refresh() {
      if (!this.selected) {
        return;
      }
      try {
        this.isLoading = true;
        let item = this.selected;
        let param = {
          id: item.device,
          name: item.name,
          device: item.device,
          select: "last",
          truck: item.name // TODO:Should Not Be Named truck
        };
        // Show Loading Here
        console.log("Refeshing");

        let temp = await this.$api.getDeviceInfoByID(item.device, param);
        if (temp.status === 200)
          this.deviceStatus.deviceInfo = Object.assign({}, temp.data);
        temp = await this.$api.getSensorByID(item.name, param);
        if (temp.status === 200) {
          //this.deviceStatus.
        }
        temp = await this.$api.getDeviceActivityByID(item.name, param);
        if (temp.status === 200) {
          this.deviceStatus.recentActivity = temp.data;
        }
        param = {
          name: item.name,
          truck: item.name,
          device: item.device,
          select: "last"
        };
        temp = await this.$api.getRadioByID(item.name, param);

        if (temp.status === 200 && temp.data.length > 0) {
          if (!Array.isArray(temp.data[0])) {
            this.deviceStatus.radioInfo = temp.data[0];
          }
        }
        //this.deviceStatus.recentActivity = temp.data;
        //await this.$api.getSensorByID(item.name,param);
        //await this.$api.getRadioByID(item.name,param);
        //await this.$api.getLocationByID(item.name,param);
      } catch (err) {
        console.warn(err);
      } finally {
        // Stop Loading Here

        //this.isLoading = false;
        setTimeout(() => (this.isLoading = false), 500);
      }
    },
    latLng(lat, lng) {
      return latLng(lat, lng);
    },
    saveGrid() {
      this.storedLayout = JSON.parse(JSON.stringify(this.layout));
      localStorage.setItem(layoutKey, JSON.stringify(this.storedLayout));
    },
    resetLayout(type) {
      this.gridKey++;
      this.layout = type === "base" ? baseLayout : this.storedLayout;
    },
    async onSelect(item) {
      // TODO: Is this device in List of Devices?
      if (item) {
        this.selected = item;
      }

      await this.refresh();

      // TODO: Handle Selected Item. Request Data from Server ( or Load Cached Data ) and update the Display
      //alert("You Selected Item: " + item.name);
      /** Use Axios to Get Latest Activity from Server as well as Radio Info and IoT Device Info  */

      /** TODO: Load the Dashboard Configuration for the Specific Device ID */
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    }
  }
};
</script>
