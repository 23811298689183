
<template>
  <CRow class="p-0 m-0">
    <CCol md="12" class="p-0 m-0">
      
      
      <CCard class="p-0 m-0 rounded" >
        <CCardHeader  class="p-0 m-0" color="gradient-info" >
        <CRow color ="info" class="justify-content-end pb-0 mb-0">
            <CCol v-if="false" class="pb-0 mb-0">
              <CInput
                label="Date"
                type="date"
                horizontal
              />
            </CCol>
            <CCol sm ="12" md="6" xs="12" class="mr-2 pt-1 pl-4 align-righ pb-0 mb-0">
              <CSelect v-if ="false"
                  class="pb-1 mb-0"
                  :options="relativeTimes"
                  :value.sync ="relativeTimeSelected"
                  
                >
                <template #prepend-content><CIcon name="cil-clock"/></template>
              </CSelect>
            
            </CCol>
            </CRow>

        </CCardHeader>
        <CCardBody class="p-0 m-0 rounded h-100 overflow-hidden dash-card">
        
          
              <GrafanaEmbeddedDashboard
              :src="urlPath"
              :from="from"
              :to="to"
              kiosk="tv"
  
              >
              </GrafanaEmbeddedDashboard>
             
            
        </CCardBody>
      </CCard>
    </CCol>
    
  </CRow>
</template>

<style>


.dash-card {
  min-height: 500px;
  height: 100%;
  
}


.box-title{
  text-align: center;
  font-size: 16px;
  float: center;
  width: 80%;
}
</style>

<script>


import GrafanaEmbeddedDashboard from "@/components/grafana/GrafanaEmbeddedDashboard"
import BaseGrafanaDashboard from "@/components/grafana/BaseGrafanaDashboard"; 

const userHistoricalDashboards = []; 
const defaultHistoricalDashboard ="https://poc.aarcomm.io/app/grafana/d/fczkUPPGz/test-dashboard-694e9bd?orgId=1&";

export default {
  name: "AnalyticsBaseView",
  components:{
    GrafanaEmbeddedDashboard, 
    
  },
  props: {
    device: Object,
  },
  extends: BaseGrafanaDashboard,
    
  data() {
    return {
      selected: null,
      key: "analytics", // TODO: Should this be a prop?? 
      //dashboardList: [], 
      slectedStart: "now-3d", 
      selectedEnd: "now",
      selectedID: "3d", 
      relativeTimes: [
        //{id:"5m", value:"5m", label:"Last 5 Minutes"},
        //{id:"10m",value:"10m", label:"Last 10 Minutes"},
        {id:"30m",value:"30m", label:"Last 30 Minutes"},
        {id:"1h",value:"1h", label:"Last 1 Hour"},
        {id:"6h",value:"8h", label:"Last 8 Hours"},
        {id:"24h",value:"24h", label:"Last 24 Hours"},
        {id:"3d",value:"3d", label:"Last 3 Days"},
        //{id:"5d",value:"5d", label:"Last 5 Days"},
        {id:"7d",value:"7d", label:"Last 7 Days"},
        {id:"14d", value:"14d",label:"Last 2 Weeks"},
        {id:"30d", value:"30d",label:"Last Month"},
        {id:"90d", value:"90d",label:"Last 3 Months"},
        //{id:"180d",value:"180d", label:"Last 6 Months"}

      ]
    };
  },
  mounted(){
    let temp;
    try {
        this.load();
    } catch (err) {
      console.log("Failed to load the users dashboards on Mount. " + err);
      // Use some defaults here
    } 
  },
   activated(){
     
     this.load();
    //this.dashboardList = this.mapData(this.$store.state.dataStore.dashboards[this.key]);
  },
  computed: {
    
    from(){
      return this.slectedStart; 
    }, 
    to(){
        return this.selectedEnd; 
    },
    relativeTimeSelected:{
        get(){
            let temp = this.relativeTimes.find(x=>x.id=== this.selectedID); 
            if(temp)
              return temp.value; 
            //return this.relativeTimes.find(x=>x.id=== this.selectedID).value
        },
        set(value){
            this.selectedID = value; 

            this.slectedStart = "now-" +value; 
            
            //alert(value); 
        }


    },
    urlPath: function () {
       if (!this.device) // 
        return defaultHistoricalDashboard;

      var temp;
      temp = this.dashboardList.find(x=>x.id== this.device.device)
      
      //If Not Found in user Database OR value is Undefined, use default 
      if(!temp || !temp.value){
        temp = defaultHistoricalDashboard; 
        temp += "&var-TruckID=" + this.device.device;
        return temp; 
      }
         //temp = temp.value; 
         return temp.value; 
      
      
      return temp; 

      

    },
  },
  methods:{
    
   
    load(){
      // TODO: Create a Library that contains this method. 
       console.log("Historical Dashboards Loaded"); 
        try{
            //this.dashboardList = this.mapData(this.$store.state.dataStore.dashboards[this.key]);
        }
        catch(err){

        }
             
         
    },
    /*
    isEmpty(key)
    {
     if(   !this.$store.state.dataStore.dashboards ||
        !this.$store.state.dataStore.dashboards[key] || this.$store.state.dataStore.dashboards[key].length===0){
          return true;  
        }
        return false; 
    },
     mapData(dashData){
        let temp = dashData.map(x => {
          return { id: x.device, value: x.url };
        });
        return temp
    }, 
    */
  }
};
</script>