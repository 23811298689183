var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { lg: "12", color: "warning" } },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("CIcon", { attrs: { name: "cil-clock" } }),
                  _vm._v(" History ")
                ],
                1
              ),
              _c(
                "CRow",
                { staticClass: "pb-0 mb-0" },
                [
                  _c(
                    "CCol",
                    {
                      staticClass: "dashboard-container ",
                      attrs: { lg: "12" }
                    },
                    [
                      _c("AnalyticsBaseView", {
                        staticClass: "p-0 m-0",
                        attrs: { device: _vm.selected }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "CElementCover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          attrs: {
            boundaries: [{ sides: ["top", "left"], query: "#media-body" }],
            opacity: 0.1
          }
        },
        [
          _c("h1", { staticClass: "d-inline" }, [_vm._v("Loading...")]),
          _c("CSpinner", { attrs: { size: "6xl", color: "info" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }